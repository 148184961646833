import React, { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import LoadingScreen from "../../components/LoadingScreen"
import QueryString from "query-string"

const SignIn = ({ location }) => {
  const { loginWithRedirect } = useAuth0()
  const params = QueryString.parse(location.search)
  useEffect(() => {
    const { redirect } = params
    if (redirect) {
      localStorage.setItem("redirect", encodeURIComponent(redirect))
    }
    loginWithRedirect({
      ui_language: (process.env.GATSBY_LANG || "it_IT").split("_")[0],
    })
  }, [])

  return <LoadingScreen />
}

export default SignIn
