import React from "react"
import { LoadingLogoIcon } from "./customIcons"

const LoadingScreen = () => {
  return (
    <div style={{ padding: 16 }} className="loading-wrapper">
      <LoadingLogoIcon />
    </div>
  )
}

export default LoadingScreen
